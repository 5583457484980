import React from 'react';
import styled from 'styled-components';
import qs from 'query-string';
import {message} from 'antd';
import {useOutletSetter} from 'reconnect.js';
import * as User from 'rev.sdk.js/Actions/User';
import GenericForm from 'rev.sdk.js/Generic/Form';
import * as AppActions from '../../AppActions';
import * as AppActionsEx from '../../AppActions/custom';
import withPageEntry from '../../withPageEntry';

function ConfirmPage(props) {
  const showLoginModal = useOutletSetter('login-modal');
  const params = qs.parse(props.location.search);
  const [uiState, setUiState] = React.useState(UISTATES.code.name);
  const [accessToken, setAccessToken] = React.useState('');

  const onTimeout = React.useCallback(async () => {
    message.warning('驗證碼已過期, 請重新註冊');
    AppActions.navigate('/forgot-password/request');
  }, []);

  const verifyCode = async (formData, extValue) => {
    const {code} = formData;
    AppActions.setLoading(true);
    try {
      const {token} = await AppActionsEx.smsForgotPasswordValidate({
        state: params.state,
        code,
      });

      setAccessToken(token);
      setUiState(UISTATES.form.name);
    } catch (ex) {
      console.warn('ConfirmPage.onSubmit ex', ex);
      message.error('驗證錯誤! 請重新註冊');
      AppActions.navigate('/forgot-password/request');
    } finally {
      AppActions.setLoading(false);
    }
  };

  const setPassword = async (formData, extValue) => {
    const {password1, password2} = formData;
    AppActions.setLoading(true);
    try {
      await AppActionsEx.smsForgotPasswordConfirm({
        password: password1,
        access_token: accessToken,
      });

      message.success('修改成功。請用新密碼登入');

      setTimeout(() => {
        AppActions.navigate('/');
      }, 600);
      setTimeout(() => {
        showLoginModal(true);
      }, 800);
    } catch (ex) {
      console.warn('ConfirmPage.onSubmit ex', ex);
      message.error('發生錯誤! 請稍後再試。');
    } finally {
      AppActions.setLoading(false);
    }
  };

  return (
    <Wrapper>
      <div className="container">
        <h1 className="title">{UISTATES[uiState].display}</h1>
        {uiState === 'code' && (
          <div>
            {params.expired && (
              <div style={{marginTop: 20, marginBottom: 20}}>
                <label>剩餘時間</label>
                <div>
                  <CountdownTimer
                    expired={params.expired}
                    onTimeout={onTimeout}
                  />
                </div>
              </div>
            )}
            <GenericForm
              instance={{
                phone: params.phone,
                email: params.email,
              }}
              schema={CODE_JSON.schema}
              uiSchema={CODE_JSON.uiSchema}
              errorSchema={CODE_JSON.errorSchema}
              onSubmit={verifyCode}
            />
          </div>
        )}
        {uiState === 'form' && (
          <GenericForm
            instance={{}}
            schema={FORM_JSON.schema}
            uiSchema={FORM_JSON.uiSchema}
            errorSchema={FORM_JSON.errorSchema}
            onSubmit={setPassword}
          />
        )}
      </div>
    </Wrapper>
  );
}

const UISTATES = {
  code: {
    name: 'code',
    display: '忘記密碼(2/3)',
  },
  form: {
    name: 'form',
    display: '忘記密碼(3/3)',
  },
};

const CODE_JSON = {
  schema: {
    title: '',
    type: 'object',
    required: ['phone', 'code'],
    properties: {
      phone: {
        type: 'string',
        title: '手機號碼',
        readOnly: true,
      },
      code: {
        type: 'string',
        title: '手機認證碼',
      },
    },
  },
  uiSchema: {},
  errorSchema: {
    code: {
      required: '手機認證碼為必填欄位',
    },
  },
};

const FORM_JSON = {
  schema: {
    title: '',
    type: 'object',
    required: ['password1', 'password2'],
    properties: {
      password1: {
        type: 'string',
        title: '設定密碼',
      },
      password2: {
        type: 'string',
        title: '再次確認密碼',
      },
    },
  },
  uiSchema: {
    password1: {
      'ui:widget': 'password',
    },
    password2: {
      'ui:widget': 'password',
    },
  },
  errorSchema: {},
};

const Wrapper = styled.div`
  padding-top: var(--topNavBarHeight);

  & > .container {
    max-width: 600px;
    width: 100%;
    min-height: 80vh;

    margin: 0 auto;
    padding: 60px 20px;

    & > .title {
      font-weight: 500;
      color: var(--primaryColor);
    }
  }
`;

function CountdownTimer(props) {
  const {expired, onTimeout} = props;
  const [msLeft, setMsLeft] = React.useState(
    expired > new Date().getTime() ? expired - new Date().getTime() : 0,
  );

  React.useEffect(() => {
    const interval = 200;
    let timer = null;

    function checkAndUpdate() {
      const msLeft = expired - new Date().getTime();
      setMsLeft(msLeft > 0 ? msLeft : 0);

      if (msLeft > 0) {
        timer = setTimeout(checkAndUpdate, interval);
      } else {
        onTimeout();
      }
    }

    if (expired - new Date().getTime()) {
      timer = setTimeout(checkAndUpdate, interval);
    } else {
      onTimeout();
    }

    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [expired, setMsLeft, onTimeout]);

  const secondLeft = Math.floor(msLeft / 1000);
  const min = Math.floor(secondLeft / 60);
  const sec = secondLeft - min * 60;

  function format(value) {
    return `00${value}`.slice(-2);
  }

  return `${format(min)}:${format(sec)}`;
}

export default withPageEntry(ConfirmPage);
